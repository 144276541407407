import dayjs from "dayjs";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import CleanedContent from "~/components/atoms/CleanedContent";
import Typo from "~/components/atoms/Typo";
import CardProjectDetail from "~/components/molecules/CardProjectDetail";
import Section from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { TENURE_ITEM } from "~/utilities/constants/tenureItems";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionProjectDetailsProps {
  className?: string;
  id: string;
  title: string;
  project: IProject;
}

export type Ref = HTMLDivElement;

const SectionProjectDetailsLDP = forwardRef<Ref, SectionProjectDetailsProps>(
  ({ className = "", id, title, project }, ref) => {
    const { t } = useTranslation();
    if (!project) return null;

    return (
      <Section
        ref={ref}
        id={id}
        className={cn(
          "flex flex-col gap-12 py-20 lg:gap-12 lg:py-12",
          className
        )}
      >
        <div className="grid grid-cols-2 gap-12 px-0 lg:grid-cols-1">
          <Typo
            className="font-bold"
            tag={ETypoTag.H2}
            color={ETypoColor.PROJECT_HEADING}
            variant={ETypoVariant.HEADER_40}
          >
            {project?.name}
          </Typo>
          <CleanedContent
            htmlContent={project.description}
            className="h-full w-full text-body-title-16 text-color"
          />
          <div className="col-span-2 col-start-2 lg:col-span-1 lg:col-start-1">
            <CardProjectDetail
              className="py-0 lg:gap-6 lg:px-0 lg:py-0"
              projectName={t(title)}
              projectDescription={{
                location: project?.location?.name,
                address: project.address,
                developer: project.developer?.name,
                totalUnits: project.totalUnits?.toString(),
                tenure: TENURE_ITEM[project.tenure]?.name,
                expectedTop: project?.expectedTop
                  ? dayjs(project?.expectedTop).format("DD MMMM YYYY")
                  : "",
                projectCategory: project.category?.name,
                googleMapUrl: "",
              }}
            />
          </div>
        </div>
      </Section>
    );
  }
);

SectionProjectDetailsLDP.displayName = "SectionProjectDetailsLDP";

export default SectionProjectDetailsLDP;
